import { IEquipe } from 'sigap/types/equipe'
import { EQUIPES, api } from 'sigap/utils/constants'

export async function listEquipes(): Promise<IEquipe[]> {
  const { data: response } = await api.get(`/${EQUIPES}`)

  return response
}
export async function listSuperintendencias(): Promise<IEquipe[]> {
  const { data: response } = await api.get(`/${EQUIPES}/superintendencia`)

  return response
}

export async function getEquipe(equipeId: string): Promise<IEquipe> {
  const { data: response } = await api.get(`/${EQUIPES}/${equipeId}`)

  return response
}

export async function insertEquipe(equipe: IEquipe): Promise<IEquipe> {
  const { data: response } = await api.post(`/${EQUIPES}`, equipe)

  return response
}
