export enum Pendencias {
  PENDENTE_FASE_1 = 'Pendente Fase 1',
  PENDENTE_FASE_2 = 'Pendente Fase 2',
  REPROVADO_FASE_1 = 'Reprovado Fase 1',
  REPROVADO_FASE_2 = 'Reprovado Fase 2',
  APROVADO_FASE_1 = 'Aprovado Fase 1',
  APROVADO_FASE_2 = 'Aprovado Fase 2'
}

export enum CalendarType {
  CALENDAR_WEEK = 'CALENDAR_WEEK',
  CALENDAR_MONTH = 'CALENDAR_MONTH'
}

export enum EntityType {
  PROJETO = 'projeto',
  CONTRATO = 'contrato',
  DEMANDA = 'demanda'
}

export enum DateFormat {
  BR_DATE = 'dd/MM/yyyy'
}

export enum Perfis {
  GERENTE = 'Gerente',
  ADMIN = 'Admin'
}

export enum Permissoes {
  FICHA = 'ficha',
  EDITAR = 'editar',
  VISUALIZAR = 'visualizar',
  APROVAR = 'aprovar'
}
