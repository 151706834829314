/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo, useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FaRegCheckCircle, FaRegTimesCircle } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import ReactSelectCreatable from 'react-select/creatable'

import { CardHeader, Checkbox, Input, useToast } from '@chakra-ui/react'
import { useRequest } from 'ahooks'
import moment from 'moment'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { AuthenticationContext } from 'sigap/context/AuthenticationContext'
import { listContratos, listContratosSubSec } from 'sigap/services/contracts'
import { getDocumentosByProject } from 'sigap/services/documentos'
import { listSuperintendencias } from 'sigap/services/equipe'
import { IIndicadoresDto, getIndicadoresByProject, insertIndicadores } from 'sigap/services/indicadores'
import { listOds } from 'sigap/services/ods'
import { editProject, insertProject } from 'sigap/services/projects'
import { listPublicoAlvo } from 'sigap/services/publicoAlvo'
import { listSedeics } from 'sigap/services/sedeics'
import { listSubsecretarias } from 'sigap/services/subsecretaria'
import { IEtapasDto, getTarefasByProject, insertTarefasOnProject } from 'sigap/services/tarefas'
import { listUsers } from 'sigap/services/users'
import { IIndicador, IProject } from 'sigap/types/projects'
import { IEtapa, ITarefa } from 'sigap/types/tarefas'
import { documentoColumns } from 'sigap/utils/constants/columns'
import { Pendencias, Permissoes } from 'sigap/utils/constants/enums'
import { PROJETO_APPROVAL_TOAST_MESSAGE, PROJETO_EDIT_TOAST_MESSAGE } from 'sigap/utils/constants/requestToastMessages'
import { GenericTable } from 'sigap/utils/generic'
import { LargeCardInfo } from 'sigap/utils/generic/CardInfo/CardInfo'
import { CommentChat } from 'sigap/utils/generic/CommentChat/CommentChat'
import { DuracaoPicker } from 'sigap/utils/generic/DuracaoPicker/DuracaoPicker'
import { FichaContainer, FichaHeader, FichaSection, FichaSubs } from 'sigap/utils/generic/FichaContainer/FichaContainer'
import { SmallFormCard } from 'sigap/utils/generic/FormCardStyles/FormCardStyles'
import { GenericButton } from 'sigap/utils/generic/GenericButton/GenericButton'
import { GenericSelect } from 'sigap/utils/generic/GenericSelect/GenericSelect'
import { NumberMaskInput } from 'sigap/utils/generic/MaskInput/MaskInput'
import { PendingAlert } from 'sigap/utils/generic/PendingAlert/PendingAlert'
import { addThreeDigits } from 'sigap/utils/methods/addThreeDigits'
import { formatCurrencyToNumber } from 'sigap/utils/methods/formatCurrencyToNumber'
import { generateOdsLabel } from 'sigap/utils/methods/generateOdsLabel'
import { returnNumberArrayFromIBasicInfoArray } from 'sigap/utils/methods/returnNumberArrayFromIBasicInfoArray'
import { returnNumberOptionArrayFromBasicInfoArray } from 'sigap/utils/methods/returnNumberOptionArrayFromBasicInfoArray'
import { returnNumberValueArrayFromMultiSelect } from 'sigap/utils/methods/returnNumberValueArrayFromMultiSelect'
import { returnStringOptionArrayFromStringArray } from 'sigap/utils/methods/returnStringOptionArrayFromStringArray'
import { returnStringValueArrayFromMultiSelect } from 'sigap/utils/methods/returnStringValueArrayFromMultiSelect'

import { EtapaList } from './EtapaList/EtapaList'
import { IndicadoresList } from './IndicadoresList/IndicadoresList'
import { ModalAprovacao } from './ModalAprovacao/ModalAprovacao'
import { ModalNovaTarefa } from './ModalNovaTarefa/ModalNovaTarefa'
import { ModalNovoDocumento } from './ModalNovoDocumento/ModalNovoDocumento'
import { ModalNovoIndicador } from './ModalNovoIndicador/ModalNovoIndicador'
import { FullyApprovedProjecteContainer } from './styles'

interface IFormData {
  nome: string
  objetivo: string
  subsecretaria: Option<number>[]
  orcamento: string
  justificativa: string
  publico_alvo: Option<number>[]
  responsavel: Option<number>
  gerente: Option<number>
  equipe: Option<number>[]
  ods: Option<number>[]
  parcerias: Option<string>[]
  sedeics: Option<number>[]
  ppa: boolean
  contratos: Option<number>[]
  // superintendencia: Option<number>[]
}

interface IProps {
  project: IProject
  refreshProject: () => Promise<IProject>
}

export const Project: React.FC<IProps> = ({ project, refreshProject }) => {
  const [showNovoIndicadorModal, setShowNovoIndicadorModal] = useState<boolean>()
  const [indicadorModalData, setIndicadorModalData] = useState<IIndicador>()
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(), new Date()])
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)
  const [tempIndicadores, setTempIndicadores] = useState<IIndicador[]>([])
  const [showNovoDocumentoModal, setShowNovoDocumentoModal] = useState<boolean>(false)
  const [documentoModalData, setDocumentoModalData] = useState<IDocumentos>()
  const [tempDocumentos, setTempDocumentos] = useState<IDocumentos[]>([])
  const [showAprovacaoModal, setShowAprovacaoModal] = useState<boolean>(false)
  const [tempEtapas, setTempEtapas] = useState<IEtapa[]>([])

  const { handleSubmit, control, setValue, watch } = useForm<IFormData>()
  const { data: sedeics } = useRequest(listSedeics)
  const SwalAlert = withReactContent(Swal)
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false)

  const { data: users, loading: usersLoading, error: usersError } = useRequest(listUsers)
  const {
    data: superintendencias,
    loading: superintendenciasLoading,
    error: superintendenciasError
  } = useRequest(listSuperintendencias)

  const { data: publicoAlvoList } = useRequest(listPublicoAlvo)
  const {
    data: subsecretarias,
    loading: subsecretariaLoading,
    error: subsecretariaError
  } = useRequest(listSubsecretarias)

  // TODO: send subsec id list to enpoint
  // const { data: contratos, loading: contratosLoading, error: contratosError } = useRequest(listContratosSubSec)
  // const [subsecIds, setSubsecIds] = useState([])
  // const [contratosParams, setContratosParams] = useState([])

  // useEffect(() => {
  //   setContratosParams([subsecIds])
  // }, [subsecIds])

  const { data: contratos, loading: contratosLoading, error: contratosError } = useRequest(listContratos)

  // const handleSubSecChange = (event) => {
  //   const { selectedOptions } = event.target
  //   const selectedIds = Array.from(selectedOptions, (option) => option.value)
  //   setSubsecIds(selectedIds)
  // }

  const { data: ods, loading: odsLoading, error: odsError } = useRequest(listOds)

  const navigate = useNavigate()

  const params = useParams()
  const {
    data: indicadores,
    refreshAsync: refreshIndicadores,
    loading: indicadoresLoading
  } = useRequest(getIndicadoresByProject, {
    defaultParams: [Number(params.id)],
    ready: !!params.id
  })

  const {
    data: documentos,
    refreshAsync: refreshDocumentos,
    loading: documentosLoading
  } = useRequest(getDocumentosByProject, {
    defaultParams: [Number(params.id)],
    ready: !!params.id
  })

  const currentFormObject = watch()
  const toast = useToast()

  const isPending = useMemo(() => project?.aprovado === null, [project])

  const isFormComplete = useMemo(
    () =>
      currentFormObject.sedeics != null &&
      currentFormObject.ods != null &&
      currentFormObject.responsavel != null &&
      currentFormObject.orcamento?.length > 0 &&
      currentFormObject.parcerias?.length > 0 &&
      currentFormObject.equipe != null &&
      currentFormObject.publico_alvo != null &&
      currentFormObject.subsecretaria != null &&
      currentFormObject.justificativa.length > 0 &&
      currentFormObject.nome.length > 0 &&
      currentFormObject.objetivo.length > 0 &&
      currentFormObject.gerente != null &&
      tempIndicadores.length > 0,
    [currentFormObject, tempIndicadores]
  )

  const isFormDisabled = useMemo(() => {
    if (project) {
      return project.permissao === Permissoes.VISUALIZAR || project.permissao === Permissoes.APROVAR
    } else {
      return false
    }
  }, [project])

  const handleSetIsEditMode = (): void => {
    setIsEditMode(true)
  }

  const returnNumberOptionFromBasicInfo = (basicInfo: IBasicInfo): Option<number> => {
    return { label: basicInfo.nome, value: basicInfo.id }
  }

  const handleDateChange = (dates: Date[]): void => {
    setSelectedDates(dates)
  }

  const handleCancelEdit = (): void => {
    refreshProject()
    setIsEditMode(false)
  }

  const handleShowNovoDocumentoModal = (): void => {
    setShowNovoDocumentoModal(true)
  }

  const normalizeProjectData = (formObject: IFormData): IProject => {
    const odsIdArray = returnNumberValueArrayFromMultiSelect(formObject.ods)
    const equipeIdArray = returnNumberValueArrayFromMultiSelect(formObject.equipe)

    const parceriasArray = returnStringValueArrayFromMultiSelect(formObject.parcerias)
    const sedeicsIdArray = returnNumberValueArrayFromMultiSelect(formObject.sedeics)
    const publicoAlvoIdArray = returnNumberValueArrayFromMultiSelect(formObject.publico_alvo)
    const subsecretariaIdArray = returnNumberValueArrayFromMultiSelect(formObject.subsecretaria)
    // const superintendenciasIdArray = returnNumberValueArrayFromMultiSelect(formObject.superintendencia)
    const contratosIdArray = returnNumberValueArrayFromMultiSelect(formObject.contratos)
    const normalizedObject: IProject = {
      equipe: equipeIdArray,
      gerente: formObject.gerente.label as string,
      justificativa: formObject.justificativa,
      nome: formObject.nome,
      objetivo: formObject.objetivo,
      publico_alvo: publicoAlvoIdArray,
      subsecretaria: subsecretariaIdArray,
      // superintendencia: superintendenciasIdArray,
      contratos: contratosIdArray,
      sedeics: sedeicsIdArray,
      ods: odsIdArray,
      // orcamento: Intl.NumberFormat('en-US', { style: 'currency', currency: 'BRL' }).format(formObject.orcamento),
      orcamento: Number(formObject.orcamento.toString().replace('.', '').replace(',', '.')),
      parcerias: parceriasArray,
      ppa: formObject.ppa,
      responsavel: [formObject.responsavel?.value],
      duracao: {
        inicio: selectedDates[0].getTime(),
        fim: selectedDates[1].getTime()
      },
      id: project?.id
    }

    return normalizedObject
  }

  const handleSaveEditProject = async (formObject: IFormData): Promise<void> => {
    try {
      setButtonLoading(true)
      const normalizedProjectObject = normalizeProjectData(formObject)
      setIsEditMode(false)
      await editProject(normalizedProjectObject)
      refreshProject()
    } finally {
      setButtonLoading(false)
    }
  }

  const handleClickSubmitEdit = async (formObject: IFormData): Promise<void> => {
    setIsLoadingUpdate(true)
    try {
      // Display the loading state
      SwalAlert.fire({
        toast: true,
        position: 'bottom',
        title: 'Carregando...',
        text: 'Por favor, aguarde.',
        showConfirmButton: false,
        didOpen: () => {
          SwalAlert.showLoading()
        },
        allowOutsideClick: false // Prevents closing the popup by clicking outside
      })

      // Call the login function (replace with actual logic)
      await handleSaveEditProject(formObject)

      // If successful, show success message
      SwalAlert.fire({
        toast: true,
        position: 'bottom',
        icon: 'success',
        title: 'Dados Salvos, projeto aguardando aprovação!',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true
      })
      setIsLoadingUpdate(false)
      refreshProject()
    } catch (AxiosError) {
      // TODO: how to make type as AxiosError
      // If there's an error, show an error message
      const message = 'fail'
      // if (AxiosError.response) {
      //   message = AxiosError.response.data.detail
      // }
      SwalAlert.fire({
        toast: true,
        position: 'bottom',
        icon: 'error',
        // title: 'Erro no servidor, por favor tente novamente.',
        title: AxiosError.response.data.detail,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
        // text: 'Something went wrong during login. Please try again.',
      })
    }
  }

  const normalizeIndicadoresDataForCreate = (): IIndicadoresDto => {
    if (tempIndicadores) {
      const indicadoresDto: IIndicadoresDto = {
        indicadores: tempIndicadores
      }

      return indicadoresDto
    }
  }

  const normalizeTarefasDataforCreate = (): IEtapasDto => {
    if (tempEtapas) {
      const newTempEtapas = tempEtapas.map((etapa) => ({
        ...etapa,
        tarefas: etapa.tarefas.map((tarefa) => ({
          ...tarefa,
          responsavel: returnNumberArrayFromIBasicInfoArray(tarefa.responsavel as IBasicInfo[]),
          subtarefas: []
        }))
      }))

      const etapasDto: IEtapasDto = {
        etapas: newTempEtapas
      }

      return etapasDto
    }
  }

  const handleRequestApproval = async (formObject: IFormData): Promise<void> => {
    try {
      setButtonLoading(true)
      const normalizedProjectObject = normalizeProjectData(formObject)
      const normalizedIndicadoresObject = normalizeIndicadoresDataForCreate()
      const normalizedTarefasObject = normalizeTarefasDataforCreate()
      const response = await insertProject(normalizedProjectObject)
      await insertIndicadores(normalizedIndicadoresObject, response.id_projeto)
      await insertTarefasOnProject(normalizedTarefasObject, response.id_projeto)

      navigate('/projetos/')
    } catch (e) {
      console.log(e)
      throw e
    } finally {
      setButtonLoading(false)
    }
  }

  const handleClickSubmitApproval = (formObject: IFormData): void => {
    toast.promise<void, Error>(handleRequestApproval(formObject), PROJETO_APPROVAL_TOAST_MESSAGE)
  }

  const generateColor = (): string => {
    // const randomColor = Math.floor(Math.random() * 16777215).toString(16)
    const colors = {
      softBlue: '#89CFF0', // Light Blue
      softGreen: '#A8D5BA', // Mint Green
      softPink: '#F5B7B1', // Blush Pink
      softPurple: '#D1C4E9', // Lavender
      softGray: '#D3D3D3', // Light Gray
      softYellow: '#FFF9B0', // Pale Yellow
      softPeach: '#FAD6A5', // Peach
      softLavender: '#E6E6FA', // Lavender Mist
      softMint: '#B5EAD7', // Mint
      softBeige: '#F5F5DC', // Beige
      softCoral: '#F7CAC9', // Soft Coral
      softTeal: '#B2DFDB', // Soft Teal
      softSand: '#F4E1D2', // Sand
      softSkyBlue: '#87CEEB', // Sky Blue
      softRose: '#F4A7B9', // Soft Rose
      softLilac: '#C8A2C8', // Lilac
      softTaupe: '#D7CCC8', // Taupe
      softCyan: '#E0FFFF', // Light Cyan
      softPlum: '#DDA0DD', // Light Plum
      softSeafoam: '#9FE2BF', // Seafoam Green
      softIvory: '#FFFFF0', // Ivory
      softLightGreen: '#C8E6C9', // Light Green
      softSunset: '#FFDAB9', // Sunset Peach
      softPastelBlue: '#AEC6CF' // Pastel Blue
    }

    const randomColor = Object.keys(colors)[Math.floor(Math.random() * Object.keys(colors).length)]

    return colors[randomColor]
  }

  const parseOrcamento = (orcamento: number): string => {
    return orcamento?.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    })
  }

  const handleOpenAprovacaoModal = (): void => {
    setShowAprovacaoModal(true)
  }

  useEffect(() => {
    if (project) {
      setIsEditMode(false)

      setValue('nome', project.nome)
      setValue('objetivo', project.objetivo)
      setValue(
        'orcamento',
        'R$ ' + Number(project.orcamento).toString().replace(',', '.')
        // project.orcamento?.toLocaleString('pt-BR', {
        //   style: 'currency',
        //   currency: 'BRL'
        // })
      )
      setValue('justificativa', project.justificativa)
      setValue('subsecretaria', returnNumberOptionArrayFromBasicInfoArray(project.subsecretaria as IBasicInfo[]))
      setValue('contratos', returnNumberOptionArrayFromBasicInfoArray(project.contratos as IBasicInfo[]))
      setValue('publico_alvo', returnNumberOptionArrayFromBasicInfoArray(project.publico_alvo as IBasicInfo[]))
      setValue('responsavel', returnNumberOptionArrayFromBasicInfoArray(project.responsavel as IBasicInfo[])[0])
      setValue(
        'gerente',
        project.gerente
          ? returnNumberOptionFromBasicInfo(project.gerente as IBasicInfo)
          : { label: undefined, value: undefined }
      )
      console.log(project)
      setValue('equipe', returnNumberOptionArrayFromBasicInfoArray(project.equipe as IBasicInfo[]))
      // setValue('superintendencia', returnNumberOptionArrayFromBasicInfoArray(project?.superintendencia))
      setValue('sedeics', returnNumberOptionArrayFromBasicInfoArray(project.sedeics as IBasicInfo[]))
      setValue('ods', returnNumberOptionArrayFromBasicInfoArray(project.ods as IBasicInfo[]))
      setValue('ppa', project.ppa)
      setValue('parcerias', returnStringOptionArrayFromStringArray(project.parcerias))
      setSelectedDates([
        new Date(addThreeDigits(project.duracao?.inicio)),
        new Date(addThreeDigits(project.duracao?.fim))
      ])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project])

  useEffect(() => {
    if (!project) {
      setIsEditMode(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project])

  return (
    <>
      <FullyApprovedProjecteContainer>
        {isPending && <PendingAlert />}
        {isEditMode ? (
          <>
            <div className="approved-project-header">
              {project?.nome ?? 'Novo projeto'}
              {project ? (
                <div className="edit-row">
                  {project.permissao === Permissoes.APROVAR && (
                    <div className="edit-button-row">
                      {isPending && (
                        <GenericButton
                          onClick={handleOpenAprovacaoModal}
                          backgroundColor={'#FFDEAD'}
                          colorScheme={'yellow'}
                        >
                          APROVAÇÃO
                        </GenericButton>
                      )}
                      <GenericButton variant={'outline'} color={'#242731'} onClick={handleCancelEdit}>
                        Voltar
                      </GenericButton>
                    </div>
                  )}
                  {project.permissao === Permissoes.EDITAR && (
                    <div className="edit-button-row">
                      <GenericButton
                        colorScheme="green"
                        onClick={handleSubmit(handleClickSubmitEdit)}
                        isLoading={buttonLoading}
                      >
                        Salvar
                      </GenericButton>
                      <GenericButton variant={'outline'} color={'#242731'} onClick={handleCancelEdit}>
                        Cancelar
                      </GenericButton>
                    </div>
                  )}
                  {project.permissao === Permissoes.VISUALIZAR && (
                    <div className="edit-button-row">
                      <GenericButton variant={'outline'} color={'#242731'} onClick={handleCancelEdit}>
                        Voltar
                      </GenericButton>
                    </div>
                  )}
                </div>
              ) : (
                <GenericButton
                  colorScheme="orange"
                  onClick={handleSubmit(handleClickSubmitApproval)}
                  isDisabled={!isFormComplete}
                  isLoading={buttonLoading}
                >
                  Requisitar aprovação
                </GenericButton>
              )}
            </div>
            <div className="approved-project-body">
              <div className="body-basic-data">
                <LargeCardInfo borderRadius={10}>
                  <CardHeader
                    backgroundColor={'#004874'}
                    borderRadius={10}
                    fontSize={16}
                    padding={'unset'}
                    paddingLeft={'12px'}
                    color={'#fff'}
                    fontFamily={'Poppins'}
                    fontStyle={'normal'}
                    fontWeight={500}
                    lineHeight={'36px'}
                  >
                    DADOS BÁSICOS DO PROJETO
                  </CardHeader>

                  <div className="large-card-info-body">
                    <div className="large-card-column">
                      <div className="label-info-bundle">
                        <label>Nome do projeto</label>
                        <Controller
                          name="nome"
                          control={control}
                          render={({ field }) => (
                            <Input variant="flushed" {...field} isDisabled={!isEditMode || isFormDisabled} />
                          )}
                        />
                      </div>
                      <div className="label-info-bundle">
                        <label>Objetivo</label>
                        <Controller
                          name="objetivo"
                          control={control}
                          render={({ field }) => (
                            <Input variant="flushed" {...field} isDisabled={!isEditMode || isFormDisabled} />
                          )}
                        />
                      </div>
                      <div className="label-info-bundle">
                        <label>Subsecretaria Responsável</label>
                        <Controller
                          name="subsecretaria"
                          control={control}
                          render={({ field }) => (
                            <GenericSelect
                              isMulti
                              options={subsecretarias?.map((subsecretaria) => ({
                                label: subsecretaria.nome,
                                value: subsecretaria.id
                              }))}
                              {...field}
                              isDisabled={!isEditMode || isFormDisabled}
                            />
                          )}
                        />
                      </div>
                      <div className="label-info-bundle">
                        <label>Orçamento Estimado</label>
                        <Controller
                          name="orcamento"
                          control={control}
                          render={({ field }) => (
                            <NumberMaskInput
                              variant="flushed"
                              format="currency"
                              currency="BRL"
                              isDisabled={!isEditMode || isFormDisabled}
                              {...field}
                            />
                          )}
                        />
                      </div>
                      <div className="label-info-bundle">
                        <label>Sedeics</label>
                        <Controller
                          name="sedeics"
                          control={control}
                          render={({ field }) => (
                            <GenericSelect
                              isMulti
                              options={sedeics?.map((subsecretaria) => ({
                                label: subsecretaria.nome,
                                value: subsecretaria.id
                              }))}
                              {...field}
                              isDisabled={!isEditMode || isFormDisabled}
                            />
                          )}
                        />
                      </div>
                      <div className="label-info-bundle">
                        <label>ODS</label>
                        <Controller
                          name="ods"
                          control={control}
                          render={({ field }) => (
                            <GenericSelect
                              isMulti
                              options={ods?.map((ods) => ({
                                label: generateOdsLabel(ods.nome, ods.id),
                                value: ods.id
                              }))}
                              {...field}
                              isDisabled={!isEditMode || isFormDisabled}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="large-card-column">
                      <div className="label-info-bundle">
                        <label>Justificativa</label>
                        <Controller
                          name="justificativa"
                          control={control}
                          render={({ field }) => (
                            <Input variant="flushed" {...field} isDisabled={!isEditMode || isFormDisabled} />
                          )}
                        />
                      </div>
                      <div className="label-info-bundle">
                        <label>Público - Alvo</label>
                        <Controller
                          name="publico_alvo"
                          control={control}
                          render={({ field }) => (
                            <GenericSelect
                              isMulti
                              options={publicoAlvoList?.map((publico_alvo) => ({
                                label: publico_alvo.nome,
                                value: publico_alvo.id
                              }))}
                              {...field}
                              isDisabled={!isEditMode || isFormDisabled}
                            />
                          )}
                        />
                      </div>
                      <div className="label-info-bundle">
                        <label>Duração do projeto</label>
                        <DuracaoPicker
                          selectedDates={selectedDates}
                          onDateChange={handleDateChange}
                          disabled={!isEditMode}
                        />
                      </div>
                      <div className="label-info-bundle">
                        <Controller
                          name="ppa"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              borderColor={'#979797'}
                              {...field}
                              value={field.value as unknown as string}
                              isChecked={currentFormObject?.ppa}
                              isDisabled={!isEditMode || isFormDisabled}
                            >
                              Orçamento previsto no PPA?
                            </Checkbox>
                          )}
                        />
                      </div>
                      <div className="label-info-bundle">
                        <label>Parcerias</label>
                        <Controller
                          name="parcerias"
                          control={control}
                          render={({ field }) => (
                            <ReactSelectCreatable
                              isMulti
                              noOptionsMessage={({ inputValue }) =>
                                !inputValue ? 'Digite para criar parceria.' : 'Nenhuma opção encontrada.'
                              }
                              isDisabled={!isEditMode || isFormDisabled}
                              placeholder={'Digitar...'}
                              {...field}
                            />
                          )}
                        />
                      </div>
                      <div className="label-info-bundle">
                        <label>Contratos</label>
                        <Controller
                          name="contratos"
                          control={control}
                          render={({ field }) => (
                            <GenericSelect
                              isMulti
                              options={contratos?.map((contrato) => ({
                                label: contrato.nome,
                                value: contrato.id
                              }))}
                              {...field}
                              isDisabled={!isEditMode || isFormDisabled}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </LargeCardInfo>
                <SmallFormCard>
                  <CardHeader
                    backgroundColor={'#004874'}
                    borderRadius={10}
                    fontSize={16}
                    padding={'unset'}
                    paddingLeft={'12px'}
                    color={'#fff'}
                    fontFamily={'Poppins'}
                    fontStyle={'normal'}
                    fontWeight={500}
                    lineHeight={'36px'}
                  >
                    EQUIPE
                  </CardHeader>
                  <div className="small-card-form">
                    <div className="label-info-bundle">
                      <label>Responsável</label>
                      <Controller
                        name="responsavel"
                        control={control}
                        render={({ field }) => (
                          <GenericSelect
                            options={users?.map((user) => ({ label: user.nome, value: user.id }))}
                            {...field}
                            isDisabled={!isEditMode || isFormDisabled}
                          />
                        )}
                      />
                    </div>
                    <div className="label-info-bundle">
                      <label>Superintendência</label>
                      <Controller
                        name="gerente"
                        control={control}
                        render={({ field }) => (
                          <GenericSelect
                            options={superintendencias?.map((superintendencia) => ({
                              label: superintendencia.nome,
                              value: superintendencia.id
                            }))}
                            {...field}
                            isDisabled={!isEditMode || isFormDisabled}
                          />
                        )}
                      />
                    </div>
                    <div className="label-info-bundle">
                      <label>Equipe</label>
                      <Controller
                        name="equipe"
                        control={control}
                        render={({ field }) => (
                          <GenericSelect
                            isMulti
                            options={users?.map((user) => ({ label: user.nome, value: user.id }))}
                            {...field}
                            isDisabled={!isEditMode || isFormDisabled}
                          />
                        )}
                      />
                    </div>
                  </div>
                </SmallFormCard>
              </div>
              <div className="body-indicadores-metas">
                <IndicadoresList
                  indicadores={indicadores}
                  tempIndicadores={tempIndicadores}
                  setShowNovoIndicadorModal={setShowNovoIndicadorModal}
                  setIndicadorModalData={setIndicadorModalData}
                  indicadoresLoading={indicadoresLoading}
                  isPending={isPending}
                />
                <EtapaList
                  isPending={isPending}
                  type="PROJETO"
                  isNewEntityScreen={!project}
                  entityId={project?.id}
                  setTempEtapas={setTempEtapas}
                  tempEtapas={tempEtapas}
                />
                <GenericTable<IDocumentos>
                  title="DOCUMENTOS"
                  columns={documentoColumns}
                  data={documentos}
                  tempData={tempDocumentos}
                  isLoading={documentosLoading}
                  onAddClick={handleShowNovoDocumentoModal}
                  onRowClick={(documento) => {
                    setDocumentoModalData(documento)
                    handleShowNovoDocumentoModal()
                  }}
                  noDataType="documentos"
                  refreshDocumentos={refreshDocumentos}
                />
              </div>
              {!!params.id && <CommentChat comments={project?.comentarios} type="PROJETO" entityId={project?.id} />}
            </div>
          </>
        ) : (
          <>
            <FichaContainer>
              {isPending ? (
                <div className="edit-row">
                  {project?.permissao === Permissoes.APROVAR && (
                    <div className="edit-mode-button noprint">
                      <GenericButton
                        onClick={handleOpenAprovacaoModal}
                        backgroundColor={'#FFDEAD'}
                        colorScheme={'yellow'}
                      >
                        APROVAÇÃO
                      </GenericButton>
                      <GenericButton onClick={handleSetIsEditMode}>Mais Detalhes</GenericButton>
                    </div>
                  )}
                  {(project?.permissao === Permissoes.EDITAR || project?.permissao === Permissoes.VISUALIZAR) && (
                    <div className="edit-mode-button noprint">
                      <GenericButton onClick={handleSetIsEditMode}>Mais Detalhes</GenericButton>
                    </div>
                  )}
                </div>
              ) : (
                <div className="edit-row">
                  {(project?.permissao === Permissoes.EDITAR ||
                    project?.permissao === Permissoes.APROVAR ||
                    project?.permissao === Permissoes.VISUALIZAR) && (
                    <div className="edit-mode-button noprint">
                      <GenericButton onClick={handleSetIsEditMode} isDisabled={isPending}>
                        Mais Detalhes
                      </GenericButton>
                    </div>
                  )}
                </div>
              )}
              <div className="ficha-body">
                <FichaSection>
                  <FichaHeader>DADOS BÁSICOS DO PROJETO</FichaHeader>
                  <FichaSubs>
                    <div className="ficha-sub-header">NOME DO PROJETO</div>
                    <div className="ficha-sub-body">{project?.nome}</div>
                  </FichaSubs>
                  <FichaSubs>
                    <div className="ficha-sub-header">JUSTIFICATIVA</div>
                    <div className="ficha-sub-body">{project?.justificativa}</div>
                  </FichaSubs>
                  <FichaSubs>
                    <div className="ficha-sub-header">OBJETIVO</div>
                    <div className="ficha-sub-body">{project?.objetivo}</div>
                  </FichaSubs>
                  <FichaSubs>
                    <div className="ficha-sub-header">SUBSECRETARIA RESPONSÁVEL</div>
                    <div className="ficha-sub-list">
                      {project?.subsecretaria?.map((subsecretaria, index) => (
                        <div key={index} style={{ backgroundColor: generateColor(), padding: '5px 10px' }}>
                          {(subsecretaria as IBasicInfo).nome}
                        </div>
                      ))}
                    </div>
                  </FichaSubs>
                  <FichaSubs>
                    <div className="ficha-sub-header">PÚBLICO ALVO</div>
                    <div className="ficha-sub-list">
                      {project?.publico_alvo?.map((publico, index) => (
                        <div key={index} style={{ backgroundColor: generateColor(), padding: '5px 10px' }}>
                          {(publico as IBasicInfo).nome}
                        </div>
                      ))}
                    </div>
                  </FichaSubs>
                  <FichaSubs>
                    <div className="ficha-sub-header">ODS</div>
                    <div className="ficha-sub-list">
                      {project?.ods?.map((ods, index) => (
                        <div key={index} style={{ backgroundColor: generateColor(), padding: '5px 10px' }}>
                          {(ods as IBasicInfo).nome}
                        </div>
                      ))}
                    </div>
                  </FichaSubs>
                  <FichaSubs>
                    <div className="ficha-sub-header">PARCERIAS</div>
                    <div className="ficha-sub-list">
                      {project?.parcerias?.map((parceria, index) => (
                        <div key={index} style={{ backgroundColor: generateColor(), padding: '5px 10px' }}>
                          {parceria}
                        </div>
                      ))}
                    </div>
                  </FichaSubs>
                  <div className="dados-basicos-final">
                    <FichaSubs>
                      <div className="ficha-sub-header">ORÇAMENTO ESTIMANDO</div>
                      <div className="ficha-sub-body">{parseOrcamento(project?.orcamento)}</div>
                    </FichaSubs>
                    <FichaSubs>
                      <div className="ficha-sub-header">DURAÇÃO DO PROJETO</div>
                      <div className="ficha-sub-body">
                        {moment(addThreeDigits(project?.duracao?.inicio)).format('L')} -{' '}
                        {moment(addThreeDigits(project?.duracao?.fim)).format('L')}
                      </div>
                    </FichaSubs>
                    <FichaSubs>
                      <div className="ficha-sub-header">ORÇAMENTO PREVISTO NO PPA?</div>
                      <div className="ficha-sub-body" style={{ marginLeft: '100px' }}>
                        {project?.ppa ? (
                          <>
                            <FaRegCheckCircle style={{ color: 'green' }} />
                          </>
                        ) : (
                          <>
                            <FaRegTimesCircle style={{ color: 'red' }} />
                          </>
                        )}
                      </div>
                    </FichaSubs>
                  </div>
                </FichaSection>
                <FichaSection>
                  <FichaHeader>INDICADORES</FichaHeader>
                  <FichaSubs>
                    <div className="ficha-sub-list">
                      {indicadores?.map((indicador, index) => (
                        <div key={index}>{indicador.nome}</div>
                      ))}
                    </div>
                  </FichaSubs>
                </FichaSection>
                <FichaSection>
                  <FichaHeader>EQUIPE</FichaHeader>
                  <div className="equipe-body" style={{ padding: '0 20px' }}>
                    <FichaSubs>
                      <div className="ficha-sub-header">RESPONSÁVEL</div>
                      <div className="ficha-sub-body">
                        {project?.responsavel?.map((responsavel, index) => (
                          <div key={index}>{(responsavel as IBasicInfo).nome}</div>
                        ))}
                      </div>
                    </FichaSubs>
                    <FichaSubs>
                      <div className="ficha-sub-header">SUPERINTENDÊNCIA</div>
                      <div className="ficha-sub-body">{project?.superintendencia}</div>
                    </FichaSubs>
                    <FichaSubs>
                      <div className="ficha-sub-header">EQUIPE</div>
                      <div className="ficha-sub-body">
                        {project?.equipe?.map((equipe, index) => (
                          <div key={index}>{(equipe as IBasicInfo).nome}</div>
                        ))}
                      </div>
                    </FichaSubs>
                  </div>
                </FichaSection>
              </div>
            </FichaContainer>
          </>
        )}
      </FullyApprovedProjecteContainer>

      <ModalNovoIndicador
        projetoId={project?.id}
        setIndicadorModalData={setIndicadorModalData}
        setShowNovoIndicadorModal={setShowNovoIndicadorModal}
        showNovoIndicadorModal={showNovoIndicadorModal}
        isNewProjectScreen={!project}
        indicadorModalData={indicadorModalData}
        refreshIndicadores={refreshIndicadores}
        setTempIndicadores={setTempIndicadores}
        tempIndicadores={tempIndicadores}
      />
      <ModalNovoDocumento
        entityId={project?.id}
        refreshDocumentos={refreshDocumentos}
        setDocumentoModalData={setDocumentoModalData}
        setShowNovoDocumentoModal={setShowNovoDocumentoModal}
        showNovoDocumentoModal={showNovoDocumentoModal}
        documentoModalData={documentoModalData}
        isNewEntityScreen={!project}
        setTempDocumentos={setTempDocumentos}
        tempDocumentos={tempDocumentos}
        type="PROJETO"
      />
      {isPending && (
        <ModalAprovacao
          projectId={project?.id}
          showAprovacaoModal={showAprovacaoModal}
          setShowAprovacaoModal={setShowAprovacaoModal}
          refreshProject={refreshProject}
        />
      )}
    </>
  )
}
