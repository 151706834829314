import styled from 'styled-components'

export const FichaContainer = styled.div`
  width: 85%;
  margin: 0 auto;
  .edit-row {
    .edit-mode-button {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 16px;
      gap: 10px;
    }
  }
`
export const FichaSection = styled.div`
  .dados-basicos-final {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgb(25 158 255 / 20%);
  }

  .equipe-body {
    display: flex;
    justify-content: space-between;
  }
  padding: 16px 0;
`

export const FichaHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  padding: 8px;
  background-color: #004874;
  margin-bottom: 8px;
  color: white;
`

export const FichaSubs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-bottom: 20px;
  .ficha-sub-header {
    font-size: 16px;
    font-weight: 200;
  }

  .ficha-sub-body {
    font-size: 18px;
    font-weight: 400;
  }

  .ficha-sub-list {
    display: flex;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    gap: 12px;
    flex-wrap: wrap;
  }
`
